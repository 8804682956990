<template>
  <div class="row">
    <div class="col">
      <h4 class="d-print-none">COTIZACION</h4>

      <h4 class="d-none d-print-block">
        ORDEN DE COMPRA
        {{
          supplier_id
            ? requirement.suppliers.filter((x) => x.id == supplier_id)[0].name
            : ""
        }}
      </h4>

      <app-table-registers
        ref="tr"
        :getList="getList"
        :bordered="true"
        :printPaginate="false"
        :border="true"
      >
        <template>
          <div class="form-inline mb-3 d-print-none">
            <select class="custom-select" v-model="supplier_id">
              <option></option>
              <option
                v-for="l in requirement.suppliers"
                :value="l.id"
                :key="l.id"
                >{{ l.name }}
              </option>
            </select>
          </div>
        </template>
        <template slot="top">
          <div class="mb-3 form-inline d-print-none">
            <button @click="$refs.elModlAddSupp.show()" class="btn btn-primary">
              <i class="fa fa-plus"></i> Agregar Proveedor
            </button>

            <!-- <CtrlMode class=" ml-1 d-inline" v-model="mode"></CtrlMode> -->

            <button
              @click="$refs.elQuMinPrice.show()"
              class="ml-1 btn btn-primary"
            >
              Seleccionar menor precio
            </button>

            <button class="btn btn-secondary" @click="$refs.elSetSupp.show()">
              <i class="fa fa-print"></i>
            </button>

            <!-- <app-button-submit
              @click="
                $refs.mForm.show();
                $refs.dForm.reset();
              "
              >Nuevo</app-button-submit
            >
            <app-button-load
              class="btn-light"
              text="Cargar productos Requeridos"
              @click="$refs.elQuestPrevLoadProd.show()"
            ></app-button-load> -->
            <app-input-search
              class="ml-auto"
              @search="
                search = $event;
                $refs.tr.gl();
              "
            ></app-input-search>
          </div>
        </template>
        <template slot="table">
          <thead>
            <tr>
              <!-- <th v-show="status_id == 2"></th> -->
              <th rowspan="2">PRODUCTO</th>
              <th rowspan="2">CATEGORIA</th>
              <th rowspan="2">CANTIDAD</th>
              <th colspan="2" v-for="l in listSuppliers" :key="l.id">
                {{ l.name }}
              </th>
            </tr>
            <tr>
              <template v-for="l in listSuppliers">
                <th :key="l.id">PRECIO</th>
                <th :key="`${l.id}_`">CANTIDAD</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="l in filterList">
              <tr :key="l.id">
                <!-- <td v-show="status_id == 2">
                <app-checkbox v-model="l.to_get" placeholder></app-checkbox>
              </td> -->
                <td>
                  <div>{{ l.product_name }}</div>
                  <span class="badge badge-light" v-show="l.brand_name"
                    >{{ l.brand_name }}
                  </span>
                </td>
                <td>{{ l.categorie_name }}</td>
                <td class="text-center">
                  <app-span-input
                    :sm="true"
                    :value="l.quantity"
                    @input="
                      l.quantity = $event;
                      saveDetail(l);
                    "
                    >{{ l.quantity }}
                    <!-- <span v-show="l.stock">
                    ({{ l.stock }})
                  </span> -->
                  </app-span-input>
                </td>
                <template v-for="l1 in listSuppliers">
                  <td :key="l1.id">
                    <app-span-input
                      v-model="l.suppliers[l1.id].price"
                      @input="setSupplierPrice(l1.id, l.product_id, $event, 1)"
                    >
                      <app-span-money
                        :moneyCode="1"
                        :quantity="l.suppliers[l1.id].price || 0"
                      ></app-span-money>
                      <!-- {{l.suppliers[l1.id].price || 0}} -->
                    </app-span-input>
                  </td>
                  <td :key="`${l1.id}_`">
                    <app-span-input
                      v-model="l.suppliers[l1.id].quantity"
                      @input="
                        setQuantitySupplier(l1.id, l.product_id, $event, l.id)
                      "
                    >
                      {{ l.suppliers[l1.id].quantity || 0 }}
                    </app-span-input>
                  </td>
                </template>
              </tr>
            </template>
            <tr :class="{ 'd-print-none': !printTotal }">
              <td colspan="3">
                <app-checkbox class="d-print-none" v-model="printTotal"
                  >Imprimir total</app-checkbox
                >
              </td>
              <td v-for="l1 in listSuppliers" :key="l1.id">
                <app-span-money
                  :quantity="totalBySupplier(l1.id)"
                  :moneyCode="1"
                ></app-span-money>
              </td>
            </tr>
          </tbody>
        </template>
      </app-table-registers>

      <app-modal-basic ref="elModlAddSupp">
        <label for="">Agregar proveedor</label>
        <SelectSupplier ref="selSup" class="mb-2"></SelectSupplier>
        <button class="btn btn-primary" @click="addSupplier()">
          <i class="fa fa-plus"></i>
        </button>
      </app-modal-basic>

      <app-modal-basic ref="elSetSupp">
        <p>Seleccionar proveedor</p>
        <table class="table">
          <tr v-for="l in requirement.suppliers" :key="l.id">
            <td>
              <a href="#" @click.prevent="setSupplierToPrint(l.id)">{{
                l.name
              }}</a>
            </td>
          </tr>
        </table>
      </app-modal-basic>

      <app-modal-yn ref="elQuMinPrice" @yes="setMinPrices()">
        <p>Seleccionar los proveedores con los precios minimos?</p>
      </app-modal-yn>
    </div>
  </div>
</template>
<script>
import { LogisticService } from "../service";
import SelectSupplier from "../suppliers/Select";
// import CtrlMode from "./CtrlPriceQuantity";
import _ from "lodash";

export default {
  components: {
    SelectSupplier
    // CtrlMode
  },

  // directives
  // filters

  props: {
    requirement_id: {}
  },

  data: () => ({
    printTotal: false,
    mode: 1,
    list: {
      data: []
    },
    per_page: 10000,
    // suppliers: [{ supplier_id: 1, supplier_name: "prov1" }],
    requirement: {
      suppliers: []
    },
    supplier_id: null
  }),

  computed: {
    listSuppliers() {
      return this.supplier_id
        ? this.requirement.suppliers.filter((x) => x.id == this.supplier_id)
        : this.requirement.suppliers;
      // return this.requirement.suppliers;
    },
    filterList() {
      // return this.list.data;
      return this.supplier_id
        ? this.list.data.filter((x) => {
            return (
              x.suppliers[this.supplier_id] &&
              x.suppliers[this.supplier_id].quantity > 0
            );
          })
        : this.list.data;
    }
  },

  watch: {
    requirement_id: {
      immediate: true,
      handler() {
        this.getRequirement();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    totalBySupplier(supplier_id) {
      let total = 0;
      for (let it of this.filterList) {
        total += parseFloat(it.suppliers[supplier_id].price) || 0;
      }
      return _.round(total, 2);
    },
    setSupplierToPrint(supplier_id) {
      this.supplier_id = supplier_id;
      this.mode = 2;
      this.$refs.tr.gl().then(() => {
        // console.log("gaa");
        this.$refs.elSetSupp.hide();
        setTimeout(() => {
          print();
        }, 300);
      });
    },
    setMinPrices() {
      LogisticService.saveRequirement({
        setMinPrices: 1,
        requirement_id: this.requirement_id
      });
    },
    getRequirement() {
      LogisticService.getRequirement(this.requirement_id).then((res) => {
        this.requirement = res;
      });
    },
    addSupplier() {
      this.requirement.suppliers.push({
        supplier_id: this.$refs.selSup.val.id,
        supplier_name: this.$refs.selSup.val.name
      });
      this.$refs.elModlAddSupp.hide();
      LogisticService.addRequirementSupplier({
        requirement_id: this.requirement_id,
        supplier_id: this.$refs.selSup.val.id
      });
    },
    setSupplierPrice(supplier_id, product_id, price, money_code) {
      if (price) {
        LogisticService.saveSupplierProduct({
          supplier_id,
          product_id,
          price,
          money_code
        });
      }
    },
    setQuantitySupplier(supplier_id, product_id, quantity, detail_id) {
      if (quantity) {
        LogisticService.saveRequirementQuantity({
          supplier_id,
          product_id,
          quantity,
          detail_id
        });
      }
    },
    getList(params) {
      return new Promise((rsv) => {
        LogisticService.getRequirementDetails({
          requirement_id: this.requirement_id,
          ...params,
          per_page: this.per_page,
          search: this.search || undefined,
          supplier_id: this.supplier_id
        }).then((res) => {
          this.per_page = res.per_page;
          this.total = res.total;
          // this.list = {
          //   data: res.data.map(x => {
          //     x.prices = {};
          //     return x;
          //   })
          // };
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
